.MuiDrawer-paper
     background: #f3f4f7!important
     box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25)!important
     border-right: none!important
     a
          color: #6F7580
     a:hover
          color:#f02633ab
          text-decoration: none
#root
     background: #f3f4f7!important
     transition: all 0.3s ease-in-out
#mainBox
     background: #f3f4f7
     color: #2E294E
     box-shadow: none

.cardBackground
     background-image: linear-gradient(to top, #f1f2f5, rgba(255, 255, 255, 0)), linear-gradient(to bottom, #f6f6f9, #f6f6f9)
     border: 1px solid rgba(238, 238, 240, 0.1)
     box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.2), -8px -8px 10px rgba(255, 255, 255, 0.6)
     border-radius: 10px
.iconBackground
     background: #f3f4f7
     box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25), -3px -3px 10px #FFFFFF
     border-radius: 6px
     padding: 5px
     color: #e74b3c
.menuName
     font-weight: 700
     font-size: 16px
     line-height: 22px
     text-transform: uppercase
     color: #6F7580
     padding: 8px 16px
.MuiButton-root
     border-radius: 10px!important
     height: 56px!important
