.card1
    width: 100%
    height: 100%
    padding: 20px
    background-image: linear-gradient(to top, #f1f2f5, rgba(255, 255, 255, 0)), linear-gradient(to bottom, #f6f6f9, #f6f6f9)
    border: 1px solid rgba(0, 0, 0, 0.1)
    // box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.13), -15px -15px 20px #FFFFFF
    box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 8%), -5px -6px 10px 0 rgb(253 253 253 / 90%)
    -webkit-box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 8%), -5px -6px 10px 0 rgb(253 253 253 / 90%)
    -moz-box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.08), -5px -6px 10px 0 rgba(253, 253, 253, 0.9)
    -ms-box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.08), -5px -6px 10px 0 rgba(253, 253, 253, 0.9)
    border-radius: 10px
.pageContainerCard
    height: 85vh!important
    margin-top: 80px
    color: #2E294E
    width: 100%
    height: 100%
    padding: 20px
    background-image: linear-gradient(to top, #f1f2f5, rgba(255, 255, 255, 0)), linear-gradient(to bottom, #f6f6f9, #f6f6f9)
    border: 1px solid rgba(0, 0, 0, 0.1)
    // box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.13), -15px -15px 20px #FFFFFF
    box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 8%), -5px -6px 10px 0 rgb(253 253 253 / 90%)
    -webkit-box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 8%), -5px -6px 10px 0 rgb(253 253 253 / 90%)
    -moz-box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.08), -5px -6px 10px 0 rgba(253, 253, 253, 0.9)
    -ms-box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.08), -5px -6px 10px 0 rgba(253, 253, 253, 0.9)
    border-radius: 10px
.MuiLinearProgress-root
    height: 12px!important
    border-radius: 6px!important
.tableContainer
    background-image: linear-gradient(to top, #f1f2f5, rgba(255, 255, 255, 0)), linear-gradient(to bottom, #f6f6f9, #f6f6f9)
    border: 1px solid #f3f4f7
    box-shadow: inset 0 0 10px #bdbdbd
    border-radius: 10px
    td
        padding: 5px!important
    td:hover
        background: #e83f2916

.MuiTableRow-head th
    background: #f0877c
    color: white
    padding: 5px
.MuiTableCell-root
    border-right: 1px solid rgba(224, 224, 224, 1)
.customChip
    display: flex
    background: #EEEEEE
    border: 2px solid #F0F0F0
    box-shadow: inset 4px 4px 8px rgba(0, 0, 0, 0.3), inset -4px -4px 10px rgba(255, 255, 255, 0.9)
    border-radius: 20px
    margin-left: 5px
    margin-bottom: 5px
    color: rgba(0, 0, 0, 0.87)
    width:300px
