.container
    height: 100vh
    position: relative
.container2
    height: calc(100vh - 180px)
    position: relative
.center
    width: 100%
    margin: 0
    position: absolute
    top: 50%
    left: 50%
    -ms-transform: translate(-50%, -50%)
    transform: translate(-50%, -50%)
.MuiInputLabel-shrink
    // color: #1f1f1f!important
    font-weight: 600!important
    margin-bottom:4px
    top: -8px!important
.MuiInputBase-root 
    background: #f3f4f7
    // border: 1px solid rgba(0, 0, 0, 0.1)!important
    box-shadow: inset 4px 4px 10px #909090b8, inset -6px -6px 14px #fff
    border-radius: 10px!important
